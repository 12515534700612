import { Modal } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { SearchResultRowFragment } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useContextualDrawers } from '../../navigation'
import { navigateToMeetingPage } from '../../navigation/utils'
import { SearchPanel } from './SearchPanel'

export interface SearchModalProps {
  isOpen: boolean
  onRequestClose?: () => void
}

export const SearchModal = ({ isOpen, onRequestClose }: SearchModalProps) => {
  const { showDrawer } = useContextualDrawers()

  const handleRowClick = useCallback(
    ({ node: { id, __typename } }: SearchResultRowFragment, event?: React.MouseEvent) => {
      switch (__typename) {
        case 'Meeting':
          capture('Search Item Selected', { target: 'Meeting' })
          navigateToMeetingPage(id, event)
          break
        case 'Goal':
          capture('Search Item Selected', { target: 'Goal' })
          showDrawer('goal', id, event)
          break
        case 'Decision':
          capture('Search Item Selected', { target: 'Decision' })
          showDrawer('decision', id, event)
          break
        case 'Action':
          capture('Search Item Selected', { target: 'Action' })
          showDrawer('action', id, event)
          break
        case 'Topic':
          capture('Search Item Selected', { target: 'Topic' })
          showDrawer('topic', id, event)
          break
      }
      if (event?.metaKey) return
      onRequestClose?.()
    },
    [showDrawer, onRequestClose],
  )

  return (
    <Modal
      sx={{ borderRadius: '8px' }}
      variant="full"
      isOpen={isOpen}
      showCloseIcon={false}
      onRequestClose={onRequestClose}
    >
      <SearchPanel handleRowClick={handleRowClick} />
    </Modal>
  )
}
