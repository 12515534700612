import { Text } from '@mm/company-ui'
import _ from 'lodash'
import React from 'react'
import { NavMeetingsListDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { NavSidebarMeeting } from './NavSidebarMeeting'

export const NavMeetingsList = () => {
  const { activeCompanyId } = useActiveCompanyId()
  const { data } = useQuery(NavMeetingsListDocument, {
    variables: {
      companyId: activeCompanyId,
    },
  })

  if (data?.me == null) {
    return null
  }

  const meetings = _.orderBy(data.me?.meetings?.edges.map(({ node }) => node) ?? [], (node) => node.title.toLowerCase())

  return (
    <>
      <Text uppercase variant="small" color="text-light" sx={{ marginLeft: 1, paddingBottom: 1, display: 'block' }}>
        Meetings
      </Text>
      {meetings.map(({ title, id }) => (
        <NavSidebarMeeting key={id} meetingId={id} label={title} />
      ))}
    </>
  )
}
