import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CompanyOrganizationBuildingIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="company-organization-building" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3528 0.800002C3.3528 0.402357 3.67515 0.0800018 4.0728 0.0800018H11.9273C12.325 0.0800018 12.6473 0.402357 12.6473 0.800002V4.07273C12.6473 4.47037 12.325 4.79273 11.9273 4.79273C11.5297 4.79273 11.2073 4.47037 11.2073 4.07273V1.52H4.7928V6.03637C4.7928 6.43401 4.47044 6.75637 4.0728 6.75637C3.67515 6.75637 3.3528 6.43401 3.3528 6.03637V0.800002ZM7.41105 14.48V8C7.41105 7.60236 7.08869 7.28 6.69105 7.28H0.800139C0.402494 7.28 0.0801392 7.60236 0.0801392 8V15.2C0.0801392 15.5976 0.402494 15.92 0.800139 15.92H6.69105L6.69223 15.92H9.30934H9.30943H9.30951H15.2004C15.5981 15.92 15.9204 15.5976 15.9204 15.2V6.03637C15.9204 5.63872 15.5981 5.31637 15.2004 5.31637H9.30951C8.91187 5.31637 8.58951 5.63872 8.58951 6.03637V14.48H7.41105ZM1.52014 14.48V8.72H5.97105V14.48H1.52014ZM10.0295 6.75637V14.48H14.4804V6.75637H10.0295ZM11.9271 7.93455C11.5294 7.93455 11.2071 8.2569 11.2071 8.65455C11.2071 9.05219 11.5294 9.37455 11.9271 9.37455H12.5816C12.9793 9.37455 13.3016 9.05219 13.3016 8.65455C13.3016 8.2569 12.9793 7.93455 12.5816 7.93455H11.9271ZM11.2071 10.6182C11.2071 10.2205 11.5294 9.89819 11.9271 9.89819H12.5816C12.9793 9.89819 13.3016 10.2205 13.3016 10.6182C13.3016 11.0158 12.9793 11.3382 12.5816 11.3382H11.9271C11.5294 11.3382 11.2071 11.0158 11.2071 10.6182ZM11.9271 11.8618C11.5294 11.8618 11.2071 12.1842 11.2071 12.5818C11.2071 12.9795 11.5294 13.3018 11.9271 13.3018H12.5816C12.9793 13.3018 13.3016 12.9795 13.3016 12.5818C13.3016 12.1842 12.9793 11.8618 12.5816 11.8618H11.9271Z"
    />
  </IconBase>
)
