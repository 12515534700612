import { Flex, Panel, PanelHeader, Text } from '@mm/company-ui'
import {
  CompanyOSLogoIcon,
  ActionsIcon,
  FilePageTemplateIcon,
  TeamIcon,
  GoalsIcon,
  DecisionIcon,
  LightbulbIdeaFeedbackIcon,
  CompanyOrganizationBuildingIcon,
  IntegrationPlugIcon,
  SingleUserIcon,
  ExternalLink,
  LibraryLearnBookIcon,
  ModeratorPresenterIcon,
} from '@mm/company-ui-icons'
import Link from 'next/link'
import React from 'react'
import { PastDueActionsBubble } from '../../actions'
import { DecisionsNeedingCommentsBubble } from '../../decisions'
import { useFeatureFlags } from '../../featureFlags'
import { useActiveUser } from '../../users'
import { useIsBeta } from '../hooks/useIsBeta'
import { NavMeetingsList } from './NavMeetingsList'
import { NavRecentMeetingsList } from './NavRecentMeetingsList'
import { NavSidebarAccount } from './NavSidebarAccount'
import { NavSidebarItem } from './NavSidebarItem'
import { NavSidebarNotifications } from './NavSidebarNotifications'
import { NavSidebarSearch } from './NavSidebarSearch'
import { NavSidebarUser } from './NavSidebarUser'

export const NavSidebar = () => {
  const {
    prototypeFeedbackTracker,
    prototypeHome,
    redNavBubbles,
    prototypeNavigationNewAccountPicker,
    mattAdminDashboardLinkInNav,
    curriculumInNav,
    meetingsBasedNavigation,
    recentMeetingsList,
    updatesInMainApp,
    homeDashboard,
  } = useFeatureFlags()
  const [isBeta] = useIsBeta()
  const activeUser = useActiveUser()
  const hasAccessToBeta = activeUser?.accessToBeta

  return (
    <Panel
      as="nav"
      sx={{
        width: 250,
        backgroundColor: 'background-light',
        flexShrink: 0,
        ...(meetingsBasedNavigation
          ? {
              backgroundColor: 'background-weak',
              borderRight: '1px solid',
              borderRightColor: 'border',
            }
          : {}),
      }}
      header={
        prototypeNavigationNewAccountPicker ? (
          <div sx={{ padding: 1, marginTop: 1 }}>
            <NavSidebarAccount hasBorder={!meetingsBasedNavigation} />
          </div>
        ) : (
          <PanelHeader border={false} offset={2}>
            <Flex align="center" gap={1.5}>
              <Link href="/">
                <a sx={{ height: '20px' }}>
                  <CompanyOSLogoIcon width={20} height={20} color="accent-background" />
                </a>
              </Link>
              {isBeta && (
                <Text
                  data-testid="NavSidebar-beta-text"
                  color="accent-text"
                  variant="small"
                  sx={{
                    border: '1px solid',
                    borderColor: 'accent-border',
                    borderRadius: 'large',
                    height: '20px',
                    paddingX: 0.75,
                  }}
                >
                  Beta
                </Text>
              )}
            </Flex>
          </PanelHeader>
        )
      }
      footer={
        <>
          <div sx={{ margin: 1 }}>
            {mattAdminDashboardLinkInNav && (
              <a
                href="https://interval.com/dashboard/os/actions"
                target="_blank"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                  height: 4,
                  paddingX: 1,
                  color: 'text-light',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  textUnderlineOffset: '3px',
                  transition: 'all 200ms',

                  '&:hover .label': {
                    textDecoration: 'underline',
                  },
                }}
                rel="noreferrer"
              >
                <SingleUserIcon />
                <Text className="label">Admin Dashboard</Text>
              </a>
            )}
            {!isBeta && hasAccessToBeta && (
              <a
                href="https://beta.companyos.app"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                  height: 4,
                  paddingX: 1,
                  color: 'text-light',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  textUnderlineOffset: '3px',
                  transition: 'all 200ms',

                  '&:hover .label': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <IntegrationPlugIcon />
                <Text className="label">Use CompanyOS Beta</Text>
              </a>
            )}
          </div>
          {!prototypeNavigationNewAccountPicker && <NavSidebarUser />}
        </>
      }
    >
      <Flex column grow sx={{ padding: 1 }}>
        <div sx={{ mb: 3 }}>
          <NavSidebarNotifications />
          <NavSidebarSearch />

          {prototypeHome && !meetingsBasedNavigation && (
            <NavSidebarItem label="Home" icon={<CompanyOrganizationBuildingIcon />} path="/prototype/home" />
          )}
        </div>

        {homeDashboard ? <NavSidebarItem label="Home" icon={<CompanyOrganizationBuildingIcon />} path="/home" /> : null}

        <NavSidebarItem label="Meetings" icon={<FilePageTemplateIcon />} path="/meetings" />

        <NavSidebarItem label="Team" icon={<TeamIcon />} path="/teams" />

        <Text
          bold
          variant="small"
          color="text-light"
          sx={{ marginLeft: 1, marginBottom: 1, marginTop: 3, display: 'block' }}
        >
          Dashboards
        </Text>

        <NavSidebarItem
          label="Actions"
          icon={<ActionsIcon />}
          path="/actions"
          right={
            redNavBubbles ? (
              <div sx={{ marginLeft: 'auto' }}>
                <PastDueActionsBubble />
              </div>
            ) : null
          }
        />

        <NavSidebarItem label="Goals" icon={<GoalsIcon />} path="/goals" />

        {updatesInMainApp ? <NavSidebarItem label="Updates" icon={<ModeratorPresenterIcon />} path="/updates" /> : null}

        <NavSidebarItem
          label="Issues"
          icon={<DecisionIcon />}
          path="/decisions"
          right={
            redNavBubbles ? (
              <div sx={{ marginLeft: 'auto' }}>
                <DecisionsNeedingCommentsBubble />
              </div>
            ) : null
          }
        />

        {prototypeFeedbackTracker && (
          <NavSidebarItem label="Feedback" icon={<LightbulbIdeaFeedbackIcon />} path="/feedback" />
        )}

        {recentMeetingsList && (
          <div data-testid="recent-meetings" sx={{ mt: 3 }}>
            <NavRecentMeetingsList />
          </div>
        )}

        {meetingsBasedNavigation && !recentMeetingsList ? (
          <div sx={{ mt: 3 }}>
            <NavMeetingsList />
          </div>
        ) : null}

        {curriculumInNav ? (
          <NavSidebarItem
            label="Curriculum"
            icon={<LibraryLearnBookIcon />}
            onClick={() => window.open('/learn', '_blank')}
            right={
              <div sx={{ marginLeft: 'auto' }}>
                <ExternalLink color="text-disabled-medium" />
              </div>
            }
          />
        ) : null}
      </Flex>
    </Panel>
  )
}
