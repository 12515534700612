import { openActionStatuses } from '@mm/common'
import { Tag, Tooltip } from '@mm/company-ui'
import React from 'react'
import { PastDueActionsBubbleDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'

export function PastDueActionsBubble() {
  const { activeCompanyId } = useActiveCompanyId()

  const query = useQuery(PastDueActionsBubbleDocument, {
    variables: {
      status: openActionStatuses,
      orderBy: 'DUE_AT_ASC',
      companyId: activeCompanyId,
    },
  })

  if (query.data?.me?.actions?.edges?.length) {
    const filteredPastDueActions = query.data.me.actions.edges.filter(
      ({ node }) => node.dueAt && new Date(node.dueAt) < new Date(),
    )

    return filteredPastDueActions.length ? (
      <Tooltip description={`${filteredPastDueActions.length} actions past due`} position="right">
        <Tag
          sx={{
            backgroundColor: 'system-background-warning-medium',
            color: 'text-inverted',
            width: 3,
            borderRadius: '50%',
            textAlign: 'center',
            display: 'block',
            fontWeight: 'bold',
            fontSize: 0,
            paddingX: 0,
          }}
        >
          {filteredPastDueActions.length}
        </Tag>
      </Tooltip>
    ) : null
  }

  return null
}
