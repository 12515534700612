import { Flex, Text } from '@mm/company-ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { isNestedRoute } from '../../../utils'

export type NavSidebarMeetingProps = {
  label: string
  icon?: React.ReactElement
  meetingId: string
}

export const NavSidebarMeeting = ({ icon, label, meetingId }: NavSidebarMeetingProps) => {
  const router = useRouter()
  const active = isNestedRoute(router, '/meetings/[id]') && router.query.id === meetingId

  return (
    <Link href={{ pathname: '/meetings/[id]', query: { id: meetingId } }} passHref={true}>
      <Flex
        as="a"
        row
        align="center"
        gap={2}
        sx={{
          height: 4,
          paddingX: 1,
          color: active ? 'accent-text' : 'text',
          cursor: 'pointer',
          textDecoration: 'none',
          textUnderlineOffset: '3px',
          transition: 'all 200ms',
          backgroundColor: active ? 'accent-background-light' : 'transparent',

          '&:hover': {
            color: 'accent-text',
            backgroundColor: 'accent-background-light',
          },
          '.hotkey-info': {
            display: 'none',
          },
          '&:hover .hotkey-info': {
            display: 'block',
          },
        }}
      >
        {icon}
        <Text className="label" overflow="ellipsis">
          {label}
        </Text>
      </Flex>
    </Link>
  )
}
