import { Tag, Tooltip } from '@mm/company-ui'
import React from 'react'
import { DecisionsNeedingCommentsDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { useActiveUserRequired } from '../../users'

export function DecisionsNeedingCommentsBubble() {
  const { activeCompanyId } = useActiveCompanyId()
  const activeUser = useActiveUserRequired()

  const query = useQuery(DecisionsNeedingCommentsDocument, {
    variables: {
      companyId: activeCompanyId,
      userId: activeUser.id,
      authorId: activeUser.id,
      participatingIn: true,
      status: ['WAITING_FOR_COMMENTS'],
    },
  })

  if (!query.data?.user) {
    return null
  }

  const decisionsNeedingComment = query.data.user.decisions.edges.filter(({ node }) => {
    return !node.comments?.edges.some(({ node }) => node.author.id === activeUser.id)
  })

  if (decisionsNeedingComment.length === 0) {
    return null
  }

  return (
    <Tooltip description={`${decisionsNeedingComment.length} issues awaiting your comment`} position="right">
      <Tag
        sx={{
          backgroundColor: 'system-background-warning-medium',
          color: 'text-inverted',
          width: 3,
          borderRadius: '50%',
          textAlign: 'center',
          display: 'block',
          fontWeight: 'bold',
          fontSize: 0,
          paddingX: 0,
        }}
      >
        {decisionsNeedingComment.length}
      </Tag>
    </Tooltip>
  )
}
