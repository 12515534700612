import styled from '@emotion/styled'
import { Flex, Text } from '@mm/company-ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { Route } from 'nextjs-routes'
import React from 'react'
import { css } from 'theme-ui'
import { isNestedRoute } from '../../../utils'

type StaticRoute = Exclude<Route, { query: any }>['pathname']

export type NavSidebarItemProps = {
  label: string
  icon: React.ReactElement
  path?: StaticRoute
  right?: React.ReactElement | null
  hotKeyInfo?: React.ReactElement | null
  onClick?: () => void
}
const HotKeyInfo = styled.div(
  css({
    marginLeft: 'auto',
    display: 'none',
  }),
)
export const NavSidebarItem = ({ icon, label, path, hotKeyInfo, right, onClick }: NavSidebarItemProps) => {
  const router = useRouter()
  const active = !!path && isNestedRoute(router, path)

  const item = (
    <Flex
      as="a"
      row
      align="center"
      gap={2}
      sx={{
        height: 4,
        paddingX: 1,
        color: active ? 'text' : 'text-light',
        cursor: 'pointer',
        textDecoration: 'none',
        textUnderlineOffset: '3px',
        transition: 'all 200ms',

        '&:hover .label': {
          textDecoration: 'underline',
        },
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`&:hover ${HotKeyInfo}`]: {
          display: 'block',
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Text bold={active} className="label" underline={active}>
        {label}
      </Text>
      {right}
      {hotKeyInfo && <HotKeyInfo>{hotKeyInfo}</HotKeyInfo>}
    </Flex>
  )

  return path ? (
    <Link href={path} passHref={true}>
      {item}
    </Link>
  ) : (
    item
  )
}
