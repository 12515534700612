import { Avatar, Button, Flex, Flyout, Text, formatUserDisplayName } from '@mm/company-ui'
import { ChevronIcon } from '@mm/company-ui-icons'
import React from 'react'
import { NavSidebarUserDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyIdOrNull } from '../../companies'
import { NavSidebarUserMenu } from './NavSidebarUserMenu'

export const NavSidebarUser = () => {
  const { activeCompanyId } = useActiveCompanyIdOrNull()
  const { data } = useQuery(NavSidebarUserDocument, {
    variables: {
      companyId: activeCompanyId ?? '',
      includeCompany: !!activeCompanyId,
    },
  })

  if (data?.me == null) {
    return null
  }

  return (
    <Flyout content={<NavSidebarUserMenu />} sx={{ width: 'auto' }}>
      <Flex
        role="button"
        row
        gap={1.5}
        align="flex-start"
        sx={{
          padding: 1,
          margin: 1,
          borderRadius: 'default',
          cursor: 'pointer',

          '&:hover': {
            backgroundColor: 'background-light-alpha',
          },
        }}
      >
        <Flex shrink={false}>
          <Avatar name={formatUserDisplayName(data.me)} />
        </Flex>

        <Flex column grow sx={{ minWidth: 0 }}>
          <Text sx={{ color: 'text' }} overflow="ellipsis">
            {formatUserDisplayName(data.me, 'FIRST_NAME_WITH_FALLBACK')}
          </Text>
          <Text variant="small" sx={{ color: 'text-light' }}>
            {data?.company?.name}
          </Text>
        </Flex>

        <Button variant="muted" size="small" startIcon={<ChevronIcon />} textHidden>
          Open menu
        </Button>
      </Flex>
    </Flyout>
  )
}
