import { Text } from '@mm/company-ui'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { NavMeetingsListDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { NavSidebarMeeting } from './NavSidebarMeeting'

export const LOCAL_STORAGE_KEY = 'RecentMeetings'

export const NavRecentMeetingsList = () => {
  const { activeCompanyId } = useActiveCompanyId()
  const { data } = useQuery(NavMeetingsListDocument, {
    variables: {
      companyId: activeCompanyId,
    },
  })

  const [recentMeetingsState] = useRecentMeetingsState()

  const meetings = data?.me?.meetings?.edges.map(({ node }) => node)

  const recentMeetings = useMemo(() => {
    return (
      _(meetings)
        .filter(({ isArchived }) => !isArchived)
        .sortBy((node) => {
          const now = Date.now()
          const savedDate = recentMeetingsState?.[node.id]
          return savedDate ? Math.abs(savedDate - now) : Infinity
        }, 'desc')
        .take(5)
        .value() || []
    )
  }, [meetings, recentMeetingsState])

  if (data?.me == null) {
    return null
  }

  return (
    <>
      <Text bold variant="small" color="text-light" sx={{ marginLeft: 1, marginBottom: 1, display: 'block' }}>
        Recent Meetings
      </Text>
      {recentMeetings.map(({ title, id }) => (
        <NavSidebarMeeting key={id} meetingId={id} label={title} />
      ))}
    </>
  )
}

export const useRecentMeetingsState = () => {
  return useLocalStorage<Record<string, number>>(LOCAL_STORAGE_KEY)
}
