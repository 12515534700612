import React from 'react'
import { Flex } from '../Flex'
import { Panel } from './Panel'

export const DEFAULT_RESIZE_KEY = 'PanelGroup_Resize_Key'

export type PanelGroupProps = {
  border?: boolean
  direction?: 'horizontal' | 'vertical'
  resize?: boolean
  resizeKey?: string
  children?: React.ReactNode
  className?: string
}

export const PanelGroup = ({ direction = 'horizontal', border = true, children, className }: PanelGroupProps) => {
  return (
    <Flex
      direction={direction === 'horizontal' ? 'row' : 'column'}
      className={className}
      sx={{
        height: '100%',
        [`& > .${Panel.name}+.${Panel.name}`]: border
          ? direction === 'horizontal'
            ? {
                borderLeft: '1px solid',
                borderLeftColor: 'border',
              }
            : {
                borderTop: '1px solid',
                borderTopColor: 'border',
              }
          : {},
      }}
    >
      {children}
    </Flex>
  )
}
