import { Text } from '@mm/company-ui'
import { SearchIcon } from '@mm/company-ui-icons'
import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { capture } from '../../analytics'
import { SearchModal } from '../../search'
import { NavSidebarItem } from './NavSidebarItem'

export const NavSidebarSearch = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  useHotkeys('cmd+/', () => {
    capture('Search Opened', { hotkey: true })
    setIsModalOpen(true)
  })
  return (
    <>
      <SearchModal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false)
        }}
      />
      <NavSidebarItem
        label="Search"
        hotKeyInfo={<Text>Cmd+/</Text>}
        icon={<SearchIcon />}
        onClick={() => {
          capture('Search Opened', {})
          setIsModalOpen(true)
        }}
      />
    </>
  )
}
