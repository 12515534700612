import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const LibraryLearnBookIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="library-learn-book" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33334 2C1.33334 0.895143 2.22849 0 3.33334 0H14C14.3682 0 14.6667 0.298477 14.6667 0.666667V12V15.3333C14.6667 15.7015 14.3682 16 14 16H3.66668C2.37782 16 1.33334 14.9555 1.33334 13.6667V2ZM13.3333 1.33333V11.3333H3.66668C3.30879 11.3333 2.96975 11.4139 2.66668 11.5578V2C2.66668 1.63152 2.96487 1.33333 3.33334 1.33333H13.3333ZM3.66668 14.6667C3.1142 14.6667 2.66668 14.2191 2.66668 13.6667C2.66668 13.1142 3.1142 12.6667 3.66668 12.6667H13.3333V14.6667H3.66668ZM5.33332 3.33333C4.96513 3.33333 4.66666 3.6318 4.66666 3.99999V7.33333C4.66666 7.70152 4.96513 8 5.33332 8H10.6667C11.0348 8 11.3333 7.70152 11.3333 7.33333V3.99999C11.3333 3.6318 11.0348 3.33333 10.6667 3.33333H5.33332ZM5.99999 6.66666V4.66666H9.99999V6.66666H5.99999Z"
    />
  </IconBase>
)
