import { ErrorBoundary, Flex, InternalErrorBanner, Panel, PanelGroup, PanelHeaderProps } from '@mm/company-ui'
import { LoadingIcon } from '@mm/company-ui-icons'
import React, { Suspense } from 'react'
import { ContextualDrawer } from './ContextualDrawer'
import { ContextualDrawerNavigationWrapper, ContextualDrawerProvider } from './ContextualDrawerProvider'
import { Head, HeadProps } from './Head'
import { NavSidebar } from './NavSidebar'

export type FrameProps = {
  children: React.ReactNode
  header?: React.ReactElement<PanelHeaderProps>
  noPanel?: boolean
  renderNavigation?: () => React.ReactNode
}

export const Frame = ({
  children,
  title,
  header,
  renderNavigation = () => <NavSidebar />,
  noPanel = false,
}: FrameProps & HeadProps) => {
  return (
    <ContextualDrawerProvider>
      <Head title={title} />
      <PanelGroup border={false}>
        {renderNavigation()}
        <ErrorBoundary fallback={<InternalErrorBanner />}>
          <Suspense
            fallback={
              <Flex
                align="center"
                justify="center"
                sx={{ height: '100%', width: '100%', backgroundColor: 'background-light' }}
              >
                <LoadingIcon width={20} height={20} color="text-disabled" />
              </Flex>
            }
          >
            {noPanel ? (
              children
            ) : (
              <Panel role="main" header={header} sx={{ flex: 1 }}>
                {children}
              </Panel>
            )}
          </Suspense>
        </ErrorBoundary>

        <ContextualDrawerNavigationWrapper>
          <ContextualDrawer />
        </ContextualDrawerNavigationWrapper>
      </PanelGroup>
    </ContextualDrawerProvider>
  )
}
