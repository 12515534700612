import Router from 'next/router'
import { route } from 'nextjs-routes'

export const navigateToMeetingPage = (id: string, event?: React.MouseEvent) => {
  const path = route({ pathname: '/meetings/[id]', query: { id } })
  if (event?.metaKey) {
    window.open(path, '_blank')
  } else {
    void Router.push(path)
  }
}
