import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ExternalLink = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="external-link" viewBox="0 0 20 20" {...props}>
      <path d="M19.164 1.31963C19.1211 1.21683 19.0583 1.12039 18.9756 1.03617C18.9717 1.03222 18.9678 1.0283 18.9638 1.02442C18.8015 0.864991 18.5789 0.766663 18.3333 0.766663H18.3333H11.6667C11.1696 0.766663 10.7667 1.16961 10.7667 1.66666C10.7667 2.16372 11.1696 2.56666 11.6667 2.56666H16.1605L8.53027 10.1969C8.1788 10.5484 8.1788 11.1183 8.53027 11.4697C8.88175 11.8212 9.45159 11.8212 9.80307 11.4697L17.4333 3.83945V8.33333C17.4333 8.83039 17.8363 9.23333 18.3333 9.23333C18.8304 9.23333 19.2333 8.83039 19.2333 8.33333V1.66666C19.2333 1.54366 19.2087 1.42642 19.164 1.31963Z" />
      <path d="M0.76667 3.33332C0.76667 2.83626 1.16961 2.43332 1.66667 2.43332H7.5C7.99706 2.43332 8.4 2.83626 8.4 3.33332C8.4 3.83038 7.99706 4.23332 7.5 4.23332H2.56667V17.4333H15.7667V13.5C15.7667 13.0029 16.1696 12.6 16.6667 12.6C17.1637 12.6 17.5667 13.0029 17.5667 13.5V18.3333C17.5667 18.8304 17.1637 19.2333 16.6667 19.2333H1.66667C1.16961 19.2333 0.76667 18.8304 0.76667 18.3333V3.33332Z" />
    </IconBase>
  )
}
