import {
  MagicBellProvider,
  NotificationList,
  NotificationPreferences,
  useNotifications,
} from '@magicbell/magicbell-react'
import { Button, Flex, Flyout, TabBar, Tag } from '@mm/company-ui'
import { BellIcon } from '@mm/company-ui-icons'
import React from 'react'
import { NavSidebarNotificationDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { NavSidebarItem } from './NavSidebarItem'

type TabKey = 'notifications' | 'settings'

const NotificationsItem = ({ user }: { user: { id: string; magicBellKey: string } }) => {
  const store = useNotifications()
  const [activeTab, setActiveTab] = React.useState<TabKey>('notifications')

  if (!store) {
    return null
  }

  const handleClick = async () => {
    const res = await fetch('https://api.magicbell.com/integrations/slack/installations/start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-MAGICBELL-API-KEY': process.env.NEXT_PUBLIC_MAGICBELL_KEY,
        'X-MAGICBELL-USER-EXTERNAL-ID': user.id,
        'X-MAGICBELL-USER-HMAC': user.magicBellKey,
      },
      body: JSON.stringify({
        slack: {
          app_id: process.env.NEXT_PUBLIC_SLACK_APP_ID,
        },
      }),
    })

    const body = (await res.json()) as { slack: { auth_url: string } }

    if (body.slack.auth_url) {
      window.open(body.slack.auth_url, '_blank') //  + `&redirect_uri=${process.env.NEXT_PUBLIC_FRONTEND_URL}slack-redirect`
    } else {
      console.error('No auth url found', body) // eslint-disable-line no-console
    }
  }

  const content = (
    <>
      <Flex
        justify="space-between"
        align="center"
        sx={{ paddingX: 2, borderBottom: '1px solid', borderBottomColor: 'border' }}
      >
        <TabBar
          tabs={[
            {
              label: 'Notifications',
              key: 'notifications',
            },
            {
              label: 'Settings',
              key: 'settings',
            },
          ]}
          activeTabKey={activeTab}
          onTabChange={setActiveTab}
        />
      </Flex>

      {activeTab === 'notifications' ? (
        <>
          <div
            sx={{
              '.infinite-scroll-component > div': {
                margin: '4px !important',
                padding: '4px 4px 4px 8px !important',
              },
            }}
          >
            <NotificationList
              height={500 - 42 - 38} // Minus Tab Height, minus bottom bar height
              notifications={store}
              queryParams={store.context}
            />
          </div>

          <Flex justify="flex-end" sx={{ borderTop: '1px solid', borderTopColor: 'border', padding: 0.5 }}>
            <Button
              size="small"
              variant="muted"
              onClick={() => {
                void store.markAllAsRead()
              }}
              sx={{ fontWeight: 'body' }}
            >
              Mark All Read
            </Button>
          </Flex>
        </>
      ) : null}

      {activeTab === 'settings' ? (
        <div sx={{ height: 400 }}>
          <div sx={{ padding: 1 }}>
            <Button onClick={handleClick}>Connect Slack</Button>
          </div>

          <NotificationPreferences />
        </div>
      ) : null}
    </>
  )

  const badge = store?.unreadCount ? (
    <div sx={{ marginLeft: 'auto' }}>
      <Tag
        variant="default"
        sx={{
          backgroundColor: 'accent-background',
          color: 'text-inverted',
          width: 3,
          borderRadius: '50%',
          textAlign: 'center',
          display: 'block',
          fontWeight: 'bold',
          fontSize: 0,
          paddingX: 0,
        }}
      >
        {store?.unreadCount > 99 ? 99 : store?.unreadCount}
      </Tag>
    </div>
  ) : null

  return (
    <Flyout
      content={content}
      contentWrapperProps={{ sx: { padding: 0, height: 500, width: 400, marginTop: 2 } }}
      sx={{ width: '100%' }}
    >
      <NavSidebarItem label="Notifications" icon={<BellIcon />} right={badge} />
    </Flyout>
  )
}

export const NavSidebarNotifications = () => {
  const { data } = useQuery(NavSidebarNotificationDocument)
  const user = data?.me

  if (!user?.magicBellKey) {
    return null
  }

  return (
    <MagicBellProvider
      apiKey={process.env.NEXT_PUBLIC_MAGICBELL_KEY}
      userExternalId={user.id}
      userKey={user.magicBellKey}
    >
      <NotificationsItem
        user={{
          id: user.id,
          magicBellKey: user.magicBellKey,
        }}
      />
    </MagicBellProvider>
  )
}
